import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hero from '../components/Hero'
import ContactBody from '../components/ContactBody'
import Layout from '../components/Layout'

function Contact({ location, data }) {
  const heroSubTitle = get(data, 'contentfulPageWebPage.subTitle')
  const heroTitle = get(data, 'contentfulPageWebPage.headline')
  const footer = data.contentfulWebComponentFooter
  const meta = data.site

  return (
    <Layout location={location} noFooter={true}>
      <Hero title={heroTitle} sub={heroSubTitle} />
      <ContactBody footer={footer} meta={meta} />
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulWebComponentFooter(slug: { eq: "footer" }) {
      id
      headline
      social {
        raw
      }
      footnote {
        footnote
      }
      contact {
        raw
      }
      address {
        raw
      }
    }
    contentfulPageWebPage(slug: { eq: "contact" }) {
      id
      headline {
        raw
      }
      subTitle
    }
  }
`
