import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Form from '../elements/Form/Form'

const ContactBody = ({ footer, meta }) => {
  const date = new Date().getFullYear()

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 className="sub-title">{children}</h5>
      },
    },
  }

  const social = JSON.parse(footer.social.raw)
  const address = JSON.parse(footer.address.raw)
  const contact = JSON.parse(footer.contact.raw)

  return (
    <section id="contactSection" className="section">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 contact-left">
            <h2 className="big-title anim-bot">How can we help you?</h2>
            <div className="row">
              <div className="col-12 col-sm-6 anim-bot">{documentToReactComponents(contact, options)}</div>

              <div className="col-12 col-sm-6 anim-bot">{documentToReactComponents(address, options)}</div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 copyright">
                <p>
                  Copyright © {date} {meta.siteMetadata.title}
                  <br />
                  {footer.footnote.footnote}
                </p>
                <div className="footer-menu">
                  <ul>{documentToReactComponents(social, options)}</ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1"></div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contact-right anim-right">
            <Form />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactBody
