import React from 'react'
import Icofont from 'react-icofont'

const FormSuccess = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'event':'generate_lead'});

  return (
    <div className="succes-message">
      <Icofont icon="check-circled" />
      <h1 className="big-title">Success!</h1>
      <p className="sub-title">We'll be in touch shortly.</p>
    </div>
  )
}

export default FormSuccess
